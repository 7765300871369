<template>
  <div class="w-full h-full px-5 pt-5">
    <div class="flex flex-col gap-6">
      <div class="flex justify-start items-center gap-8">
        <h1 class="text-xl text-left font-extrabold">
          Documents
        </h1>
        <breadcrumb :items="breadcrumbs" />
      </div>

      <Tab
        :tabs="tabs"
        border
        :active-tab="currentTab"
        @currentTab="currentTab = $event"
        v-model="currentTab"
      />
      <div class="h-full">
        <Documents
          v-if="currentTab === 'Documents'"
          :currentTab="currentTab"
        />
        <Employees
          v-if="currentTab === 'Shared Documents'"
        />

      </div>
    </div>  
  </div>     
</template>

<script>
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import Tab from "@scelloo/cloudenly-ui/src/components/tab";
  
  export default {
    name: "EmployeeDocs",
    components: {
      Breadcrumb,
      Tab,
      Employees: () => import("./sharedDocuments/index"),
      Documents: () => import("./documents/index")
    },
    data() {
      return {
        currentTab: this.$route.query.currentTab || "Documents",
        tabs: ["Documents", "Shared Documents"]
      };
    },
    computed: {
      breadcrumbs() {
        return [
          {
            disabled: false,
            text: "Documents",
            href: "OrganisationCompanyDocuments",
            id: "DocumentMgt",
          },
          {
            disabled: false,
            text: "Employee Documents",
            href: "EmployeeDocuments",
            id: "EmpDocuments",
          },
        ];
      }
    },
    methods: {
    }
  };
</script>

<style scoped>
</style>
  